import "/app/node_modules/core-js/modules/es.array.iterator.js";
import "/app/node_modules/core-js/modules/es.promise.js";
import "/app/node_modules/core-js/modules/es.object.assign.js";
import "/app/node_modules/core-js/modules/es.promise.finally.js";
import VueKonva from "vue-konva";
import dayjs from "dayjs";
import { createPinia } from 'pinia';
import relativeTime from "dayjs/plugin/relativeTime";
import isBetween from "dayjs/plugin/isBetween";
import utc from "dayjs/plugin/utc";
import * as Sentry from "@sentry/vue";
import config from "../package.json";
import App from "./App.vue";
import scrollDirective from "@/directives/scroll";
import store from "./store/store";
import router from "./router/router";
import updater from "@/lib/websocket/updater";
import "reset-css";
import "./assets/styles/index.css";
dayjs.extend(relativeTime);
dayjs.extend(isBetween);
dayjs.extend(utc);
var app = createApp(App);
var pinia = createPinia();
app.directive("scroll", scrollDirective);
app.use(VueKonva);
app.use(router);
app.use(store);
app.use(pinia);
updater.start();
console.log("APP version: ".concat(config.version));
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    app: app,
    dsn: process.env.VUE_APP_SENTRY_URL,
    integrations: [new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    })],
    tracesSampleRate: 1.0,
    tracingOptions: {
      trackComponents: true
    },
    ignoreErrors: ["Request failed with status code 401", 'Redirected when going from "/login" to "/dashboard" via a navigation guard']
  });
}

// app.config.performance = true;
// app.config.devtools = true;

app.mount("#app");