import axios from "axios";

import { formatTransactionsForTable } from "@/utils/formatters";
import { getUniqueListBy } from "@/utils/helpers";
import client from "@/utils/axios";

let cancel;

export default {
  namespaced: true,

  state: {
    transactions: [],
  },

  actions: {
    async fetch({ commit }, { parameters, clear } = {}) {
      if (cancel) {
        cancel.cancel();
      }

      cancel = axios.CancelToken.source();

      if (clear) {
        commit("setTransactions", []);
      }

      try {
        const { data } = await client.get(`/api/v2/my/transactions`, {
          params: parameters,
          cancelToken: cancel.token,
        });

        commit("setTransactions", data);
      } catch (e) {
        console.log("Failed to fetch transactions", e);
      }
    },

    async update({ state, getters, commit }, newTransactions) {
      const { internalIdToTransactionMap } = getters;

      for (let newTransaction of newTransactions) {
        const transactionToUpdate = internalIdToTransactionMap[newTransaction.internalId];

        if (!transactionToUpdate) {
          continue;
        }

        internalIdToTransactionMap[newTransaction.internalId] = newTransaction;
      }

      commit("setTransactions", [...state.transactions]);
    },

    async add({ state, commit }, newTransactions) {
      let all = getUniqueListBy(
        [...newTransactions, ...state.transactions],
        "id"
      );

      if (all.length > 2000) {
        all = all.slice(0, all.length - newTransactions.length);
      }

      commit("setTransactions", all);
    },
  },

  getters: {
    transactions(state) {
      return formatTransactionsForTable(state.transactions);
    },
    latest10Transactions(_, getters) {
      const { transactions } = getters;
      return transactions.slice(0, 10);
    },
    internalIdToTransactionMap(state) {
      return state.transactions.reduce((memo, transaction) => {
        const { internalId } = transaction;
        memo[internalId] = transaction;
        return memo;
      }, {});
    }
  },

  mutations: {
    setTransactions(state, data) {
      state.transactions = data;
    },
  },
};
