import dayjs from "dayjs";

import { useCurrency } from "@/composables/useCurrency";

import { MAX_PORTFOLIO_PRECISION } from "@/constants";
import { numbro } from "@/utils/filters";
import { add, isZero, isGreaterThan } from "@/utils/math";

function getLocalTimestamp(date) {
  return (dayjs(date).unix() + dayjs(date).utcOffset() * 60) * 1000;
}

export const noop = () => {};

export const pick = (obj, fields) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => fields.includes(key))
  );
};

export const omit = (obj, fields) => {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([key]) => !fields.includes(key))
  );
};

export const flattenDeep = (val) => {
  return Object.values(val || []).reduce(
    (acc, val) =>
      typeof val === "object" ? acc.concat(flattenDeep(val)) : acc.concat(val),
    []
  );
};

export const hashObject = (val) => {
  const stack = [...Object.values(val || [])];
  const result = [];

  while (stack.length) {
    const current = stack.pop();

    if (current && typeof current === "object" && !Array.isArray(current)) {
      stack.push(...Object.values(current));
    } else if (Array.isArray(current)) {
      stack.push(...current);
    } else if (typeof current === "string" || typeof current === "number") {
      result.push(current);
    }
  }

  return result.reverse(); 
};

export const isNullOrUndefined = (value) => {
  return typeof value === "undefined" || value === null;
};

export const formatCurrencyCasing = (value) => {
  return value ? value.toUpperCase() : null;
};

export const exportCsv = (array, fileName) => {
  const headers = Object.keys(array[0]);
  array.unshift(headers);

  let csv = "";

  for (const item of array) {
    let line = "";
    for (let index in item) {
      if (line != "") line += ",";

      line += item[index] || "";
    }

    csv += line + "\r\n";
  }

  const exportedFilename = fileName + ".csv" || "export.csv";
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, exportedFilename);
  } else {
    let link = document.createElement("a");
    if (link.download !== undefined) {
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const prepareTradesFilters = (filters, side = "all") => {
  let params = {
    ...filters,
  };
  if (params.dateRange) {
    params.from = getLocalTimestamp(params.dateRange.start);
    params.dateRange.end.setHours(23);
    params.dateRange.end.setMinutes(59);
    params.to = getLocalTimestamp(params.dateRange.end);
    delete params.dateRange;
  } else {
    delete params.dateRange;
  }
  delete params.search;
  if (side !== "all") {
    params.side = side;
  }
  return params;
};

export const saveToLocalStorage = (key, value) => {
  const valueToSave = JSON.stringify(value);
  localStorage.setItem(key, valueToSave);
};

export const getFromLocalStorage = (key) => {
  const value = localStorage.getItem(key);

  let valueTo = null;

  if (value && value !== "undefined") {
    try {
      valueTo = JSON.parse(value);
    } catch {
      valueTo = value;
    }
  }

  return valueTo;
};

export const removeFromLocalStorage = (key) => {
  return localStorage.removeItem(key);
};

export const getAssetToBalanceMap = (obj) => {
  return Object.entries(obj).reduce((memo, [currency, arr]) => {
    const data = arr.reduce(
      (memo, { total, totalConverted }) => {
        memo.total = add(total, memo.total);
        memo.totalConverted = add(totalConverted ?? "0", memo.totalConverted);

        return memo;
      },
      { total: "0", totalConverted: "0" }
    );

    memo[currency] = data;

    return memo;
  }, {});
};

export const getAssetToPositiveBalanceMap = (obj) => {
  return Object.entries(obj).reduce((memo, [currency, arr]) => {
    const data = arr
      .filter((item) => {
        return isGreaterThan(item?.total, 0)
      })
      .reduce(
        (memo, { total, totalConverted }) => {
          memo.total = add(total, memo.total);
          memo.totalConverted = add(totalConverted ?? "0", memo.totalConverted);

          return memo;
        },
        { total: "0", totalConverted: "0" }
      );

      memo[currency] = data;

      return memo;
    }, {});
};

export const obscuredEmail = (email) => {
  if (!email) {
    return "";
  }

  const [name, domain] = email.split("@");

  const visiblePart = name.slice(-3);
  const hiddenPart = Array.from(name.slice(0, name.length - 3))
    .fill("*")
    .join("");

  return `${hiddenPart}${visiblePart}@${domain}`;
};

export const copyInClipboard = (value) => {
  navigator.clipboard.writeText(value);
};

export const getRoundedDownValue = (value, currency, roundDown = true) => {
  const store = require("@/store/store");
  const state = store.default.state;
  const getters = store.default.getters;
  const { getCurrencyPrecision } = getters;
  const { convertTo } = useCurrency();
  const computedCurrency = currency || convertTo.value;

  const mantissa = Math.min(getCurrencyPrecision(computedCurrency), MAX_PORTFOLIO_PRECISION);

  return numbro(value, computedCurrency, {
    rule: state.config.uiFormatters.ROUNDING_RULES.CUSTOM_PRECISION,
    mantissa,
    roundDown,
  });
};

export const filterSearch = (item, search, searchFields) => {
  if (!search) {
    return true;
  }

  const fields = pick(item, searchFields);

  return (
    hashObject(fields)
      .join("_")
      .replace(/,/g, "")
      .toLowerCase()
      .indexOf(search.toLowerCase()) > -1
  );
};

export const getUniqueListBy = (arr, key) => {
  if (!key) {
    return [...new Set(arr)];
  }
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const abbreviateNumber = (number) => {
  const value = number.replace(/,/g, "");
  const absValue = Math.abs(value);

  if (absValue >= 1e3 && absValue < 1e6) {
    return +(value / 1e3).toFixed(1) + "K";
  }

  if (absValue >= 1e6 && absValue < 1e9) {
    return +(value / 1e6).toFixed(1) + "M";
  }

  if (absValue >= 1e9 && absValue < 1e12) {
    return +(value / 1e9).toFixed(1) + "B";
  }

  return number;
};

export const startInterval = (callback, interval) => {
  let counter = 1;
  let timeoutId;
  const startTime = Date.now();

  function main() {
    const nowTime = Date.now();
    const nextTime = startTime + counter * interval;
    timeoutId = setTimeout(main, interval - (nowTime - nextTime));

    counter += 1;

    callback();
  }

  timeoutId = setTimeout(main, interval);

  return () => {
    clearTimeout(timeoutId);
  };
};

export const formatCurrencyValue = (value, noRounding = false) => {
  if (!value) {
    return "0";
  }
  if (noRounding) {
    const parts = value.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
  return new Intl.NumberFormat("en-EN").format(value);
};

export const formatDate = (value) => {
  return new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(value);
};

export const getExportCsvFilename = (type) => {
  const store = require("@/store/store");
  const state = store.default.state;

  const datetime = dayjs()
    .utc()
    .format(state.config.uiFormatters.DATE_TIME_FORMAT);
  return `${type}-${datetime}`;
};

export const sliceIntoChunks = (arr, chunkSize) => {
  let res = [];

  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }

  return res;
};

export const findIndexInArray = (arr, cb) => {
  let i = 0

  while (i < arr.length) {
    if (cb(arr[i])) {
      return i
    }
    i++
  }

  return -1
};

export const matchAmlAccount = (item) => {
  const store = require("@/store/store");
  const { exchangeNames } = store.default.getters;

  const { bankAccount, exchangeId, currency } = item;

  return bankAccount && [exchangeNames.BEQUANT, exchangeNames.BINANCE].includes(exchangeId) && item.funding && !isZero(item.funding[currency]) && isGreaterThan(item.fundingConverted[currency].USDT, 0.01);
}

export const isProduction = () => {
  return process.env.NODE_ENV === 'production';
};

export const memoize = (fn) => {
  const cache = {};
  return (args) => {
    const key = `${args.toString()}`;

    if (key in cache) {
      return cache[key];
    }
    
    return cache[key] = fn(args);
  };
};
