export const READABLE_DATE_FORMAT = "DD MMMM YYYY";

export const STATISTICS_PERIOD = 24;
export const STATISTICS_PERIOD_UNIT = "hours";

export const MAX_PORTFOLIO_PRECISION = 6;

export const MAX_API_KEYS = 3;

export const UI_FORMATTERS = {
  BTC_PRECISION_MANTISSA: 6,
  DATE_FORMAT: "YYYY-MM-DD",
  DATE_TIME_FORMAT: "YYYY-MM-DD HH:mm:ss",
  LONG_PRECISION_MANTISSA: 8,
  MAX_PRECISION_MANTISSA: 20,
  ROUNDING_RULES: {
    DEFAULT: 0,
    LONG_PRECISION: 1,
    SHORT_PRECISION: 2,
    CUSTOM_PRECISION: 3,
    MAX_PRECISION: 4,
  },
  CUSTOM_PRECISION: 3,
  DEFAULT: 0,
  LONG_PRECISION: 1,
  MAX_PRECISION: 4,
  SHORT_PRECISION: 2,
  SHORT_PRECISION_MANTISSA: 2,
  TIME_FORMAT: "HH:mm:ss",
};

export const TRANSFER_STATUS = {
  NEW: 0,
  SUCCESS: 1,
  FAILURE: 2,
};

export const WITHDRAW_STATUS = {
  NEW: 0,
  SUCCESS: 1,
  FAILURE: 2,
};

export const TRANSACTION_STATUS = {
  COMPLETED: 0,
  PENDING: 1,
  IN_PROGRESS: 2,
  FAILED: 3,
  AWAITING_APPROVAL: 4,
  SETTLED: 5,
  ROLLBACK: 6
};

export const TRANSACTION_TYPE = {
  PAYIN: "payin",
  PAYOUT: "payout",
  TRANSFER: "transfer",
  DEPOSIT: "deposit",
  WITHDRAW: "withdraw",
  PB_FEE: "pb-fee",
  EARN_INITIAL: "earn-initial",
  EARN_PARTIAL_WITHDRAWAL: "earn-partial-withdrawal",
  EARN_ADD_TO_DEPOSIT: "earn-add-to-deposit",
  EARN_INTEREST_WITHDRAWAL: "earn-interest-withdrawal",
  EARN_FULL_WITHDRAWAL: "earn-full-withdrawal",
  INSTANT_TRANSFER: "instant-transfer"
};

export const TRANSACTION_SIDE = {
  ZERO: 0,
  POSITIVE: 1,
  NEGATIVE: -1,
};

export const TRANSACTIONS_HASH_IDENTIFIERS = [
  "0x",
  "r",
  "X",
  "1",
  "3",
  "bc1",
  "T",
];

export const TRANSACTIONS_WEB_SITES_BY_IDENTIFY = {
  "0x": "https://etherscan.io/tx/",
  r: "https://xrpscan.com/tx/",
  X: "https://xrpscan.com/tx/",
  1: "https://www.blockchain.com/btc/tx/",
  3: "https://www.blockchain.com/btc/tx/",
  bc1: "https://www.blockchain.com/btc/tx/",
  T: "https://tronscan.org/#/transaction/",
};

export const TRADE_SIDE = {
  BUY: 1,
  SELL: -1,
};

export const API_KEY_STATUS = {
  ACTIVE: "active",
  DELETED: "deleted",
  BLOCKED: "blocked",
};

export const WEBSOCKET_EVENTS = {
  SUBSCRIPTION_ID_GENERATED: "subscription-id-generated",
  BALANCES_UPDATED: "balances-updated",
  AUTHORIZE_SUCCESS: "authorize-success",
  UNAUTHORIZED: "unauthorized",
  PING: "ping",
  PONG: "pong",
  SEND_ACTIVE_PORTFOLIO: "change-active-portfolio",
  PRICES_UPDATED: "prices-updated",
  SEND_TOKEN: "authorize",
  NOTIFICATION_CREATED: "notification-created",
  AGGREGATED_PARAMETER_REMOVED: "aggregated-params-deleted",
  AGGREGATED_PARAMETER_UPDATED: "aggregated-params-updated",
  POSITION_REMOVED: "position-deleted",
  POSITION_UPDATED: "position-updated",
  COLLATERAL_UPDATED: "collateral-updated",
  TRANSACTIONS_CREATED: "transactions-created",
  TRANSACTIONS_UPDATED: "transactions-updated",
  TRADES_CREATED: "trades-created",
  TRADING_ORDERBOOK_UPDATED: "trading-orderbook-updated",
  TRADING_LAST_CANDLE_UPDATED: "trading-last-candle-updated",
  TRADING_TRADES_UPDATED: "trading-trades-updated",
  TRADING_ORDER_UPDATED: "trading-order-updated",
};

export const USER_STATUS = {
  PENDING_EMAIL_CONFIRMATION: 0,
  PENDING_KYC: 1,
  KYC_REJECTED: 2,
  DISABLED: 3,
  ACTIVE: 4,
};


export const USER_PERMISSIONS = {
  READ_ALL_PORTFOLIOS: 'read-all-portfolios',
  RESET_2FA: 'reset-2fa',
  RESEND_EMAIL_CONFIRMATION: 'resend-email-confirmation',
  CHANGE_USER_PASSWORD: 'change-user-password',
  READ_ALL_USERS: 'read-all-users',
  MANAGE_USERS: 'manage-users',
  MANAGE_PORTFOLIOS: 'manage-portfolios',
  MANAGE_APIKEYS: 'manage-apikeys',
  MAKE_TRANSFERS: 'make-transfer',
  MANAGE_PUBLIC_API_KEYS: 'manage-public-api-keys',
  VIEW_EXCHANGE_API_KEYS_SECRETS: 'view-exchange-api-keys-secrets',
  RENAME_ACCOUNTS: 'rename-accounts',
  WITHDRAW: 'withdraw',
  MANAGE_WITHDRAW_ADDRESSES: 'manage-withdraw-addresses',
  VIEW_WITHDRAW_ADDRESSES: 'view-withdraw-addresses',
  BYPASS_2FA: 'bypass-2fa',
  MANAGE_AIRFLOW: 'manage-airflow',
  MANAGE_EARN: 'manage-earn',
  APPROVE_DEFI_AMOUNT: 'approve-defi-amount',
  MANAGE_DEFI: 'manage-defi',
  VIEW_DEFI: 'view-defi',
  MANAGE_CHAIN_ALIASES: 'manage-chain-aliases',
  TRADING: 'trading',
  MANAGE_EXCHANGE_FEE_RATES: 'manage-exchange-fee-rates',
  MANAGE_CHARGING_ACCOUNT: 'manage-charging-account',
  CREATE_QUOTATION_REQUEST: 'create-quotation-request',
  CREATE_LOAN_REQUEST: 'create-loan-request',
  MANAGE_TRANSACTION_RESTRCTIONS: 'manage-transaction-restrictions',

  PAGES_DASHBOARD: 'pages.dashboard',
  PAGES_PORTFOLIO: 'pages.portfolio',
  PAGES_RISKQUANT: 'pages.riskquant',
  PAGES_EXCHANGE: 'pages.exchange',
  PAGES_OTC: 'pages.otc',
  PAGES_OPEN_ORDERS_POSITIONS: 'pages.open-orders-positions',
  PAGES_LOANS: 'pages.loans',
  PAGES_LOANS_OPTIONS_TAB: 'pages.loans-options-tab',
  PAGES_EARN: 'pages.earn',
  PAGES_DEFI: 'pages.defi',
  PAGES_TRADES: 'pages.trades',
  PAGES_TRANSACTIONS: 'pages.transactions',
  PAGES_FEES_TURNOVER: 'pages.fees-turnover',
  PAGES_PNL: 'pages.pnl',
  PAGES_ANALYTICS: 'pages.analytics',
  PAGES_ACCOUNTS: 'pages.accounts',
  PAGES_CURRENCIES_AVAILABILITY: 'pages.currencies-availability',
  PAGES_SECURITY: 'pages.security',
  PAGES_FEE_SCHEDULE: 'pages.fee-schedule',
  PAGES_PUBLIC_API_KEYS: 'pages.public-api-keys',
  PAGES_DEPOSIT_WITHDRAW_TRANSFER_SECTION: 'pages.deposit-withdraw-transfer-section'
};

export const TETHER_CHAINS_CURRENCIES = ["USD", "USDT20", "USDTRX", "USDT"];

export const WEBSOCKET_INTERVALS = {
  BALANCE_UPDATE: 10000,
  TRANSACTIONS_DEBOUNCE: 1000,
  TRADES_DEBOUNCE: 1000,
};

export const SMALL_BALANCES = {
  BTC: 0.01,
  ETH: 0.1,
  USDT: 100,
};

export const ZERO_CONVERTED_BALANCES = { BTC: "0", ETH: "0", USDT: "0" };