import axios from 'axios';

import client from "@/utils/axios";

let cancelToken;
let inTransferCancelToken;

export default {
  namespaced: true,

  state() {
    return {
      hideSmallBalances: false,
      totalAssets: null,
      assetsInTransfers: null,
      filters: {
        exchangeId: [],
        currency: [],
        apiKeyId: [],
        formattedAccountType: [],
      },
    };
  },

  actions: {
    async fetchPortfolioTotalAssets({ commit }) {
      if (cancelToken) {
        cancelToken.cancel();
      }
  
      cancelToken = axios.CancelToken.source();

      try {
        const { data } = await client.get("/api/v1/portfolio/assets", {
          cancelToken: cancelToken.token,
        });

        commit("setPortfolioTotalAssets", data);
      } catch (e) {
        if (axios.isCancel(e)) {
          return;
        }

        commit("setPortfolioTotalAssets", null);
      }
    },

    async fetchAssetsInTransfer({ commit }) {
      if (inTransferCancelToken) {
        inTransferCancelToken.cancel();
      }
  
      inTransferCancelToken = axios.CancelToken.source();

      try {
        const { data } = await client.get("/api/transfer/list/in-progress", {
          cancelToken: inTransferCancelToken.token,
        });

        commit("setAssetsInTransfers", data);
      } catch (e) {
        if (axios.isCancel(e)) {
          return;
        }

        commit("setAssetsInTransfers", null);
      }
    },

    resetFilters({ commit }) {
      commit("setPortfolioFilters", {
        exchangeId: [],
        currency: [],
        apiKeyId: [],
        formattedAccountType: [],
      });
    },
  },

  getters: {
    isEmptyFilters(state) {
      return (
        state.filters.formattedAccountType.length === 0 &&
        state.filters.exchangeId.length === 0 &&
        state.filters.apiKeyId.length === 0 &&
        state.filters.currency.length === 0
      );
    },
    filtersSet(state) {
      const { filters } = state;

      return Object.entries(filters).reduce((memo, [key, values]) => {
        memo[key] = new Set(values)
        return memo;
      }, {});
    },
    assetsInTransfersMap(state) {
      const { assetsInTransfers } = state;

      return (assetsInTransfers || []).reduce((memo, item) => {
        const { 
          currency, 
          fromApiKeyId, 
          fromAccountType,
          toApiKeyId,
          toAccountType
        } = item;
        const { inTransfers, outTransfers } = memo;

        const key1 = `${fromApiKeyId}:${currency}:${fromAccountType}`;
        const key2 = `${toApiKeyId}:${currency}:${toAccountType}`;

        if (outTransfers[key1]) {
          outTransfers[key1].push(item);
        } else {
          outTransfers[key1] = [item];
        }

        if (inTransfers[key2]) {
          inTransfers[key2].push(item);
        } else {
          inTransfers[key2] = [item];
        }

        return memo;
      }, { 
        inTransfers: {}, 
        outTransfers: {} 
      });
      }
  },

  mutations: {
    setPortfolioTotalAssets(state, data) {
      state.totalAssets = data;
    },

    setAssetsInTransfers(state, data) {
      state.assetsInTransfers = data;
    },

    setPortfolioFilters(state, filters) {
      state.filters = { ...state.filters, ...filters };
    },

    setHideSmallBalances(state, hideSmallBalances) {
      state.hideSmallBalances = hideSmallBalances;
    },
  },
};
