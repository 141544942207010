import { getRoundedDownValue } from "@/utils/helpers";
import { add } from "@/utils/math";

import { CURRENCIES } from "@/constants/currencies";

const USDT_CURRENCIES = ["USDT_OMNI", "USDT_ERC20", "USDT_TRC20"];
const USDT_CURRENCIES_SET = new Set(USDT_CURRENCIES);

function mapXBTToBTC(item) {
  if (item.currency === "XBT") {
    return {
      ...item,
      currency: "BTC",
    };
  }
  return item;
}

function getInstrumentsByCurrency(currency) {
  const store = require("@/store/store");
  const quoteCurrencyToInstrumentsMap = store.default.getters["config/quoteCurrencyToInstrumentsMap"];

  if (!currency) {
    return [];
  }

  return quoteCurrencyToInstrumentsMap[currency] || [];
}

function getEmptySpot(apiKeyId, currency, exchangeId) {
  const instruments = getInstrumentsByCurrency(currency);

  return instruments.map((instrument) => {
    return {
      exchangeId,
      apiKeyId,
      accountType: `${instrument.contractType}-${instrument.contract}`,
      currency: instrument.quoteCurrency,
      balance: 0,
    };
  });
}

function getBitfinexMarginApiKeys(currency) {
  const store = require("@/store/store");
  const state = store.default.state;
  const getters = store.default.getters;
  const { exchangeNames, getExchangeFullName, derivativesAccountsMap } = getters;
  const getApiKey = getters["portfolio/getApiKey"];

  function checkAccount(item, type) {
    const key = `${item.id}:${item.currency}:${type}`;
    return derivativesAccountsMap[key];
  }

  return state.portfolio.activePortfolioApiKeys.reduce((memo, item) => {
    const existing = checkAccount(item, "margin-cross");

    const condition =
      item.exchangeId === exchangeNames.BITFINEX &&
      item?.restrictions?.margin_enabled &&
      !existing;

    if (condition) {
      const exchangeFullName = getExchangeFullName(item.exchangeId);
      const { name: apiKeyName } = getApiKey(item.id) ?? {};

      memo.push({
        exchangeId: item.exchangeId,
        apiKeyId: item.id,
        accountType: "margin-cross",
        apiKeyName,
        currency,
        total: 0,
        exchangeFullName,
      });
    }

    return memo;
  }, []);
}

function getBitfinexPerpetualApiKeys(currency) {
  const store = require("@/store/store");
  const state = store.default.state;
  const getters = store.default.getters;
  const getApiKey = getters["portfolio/getApiKey"];
  const getExchangeFullName = getters.getExchangeFullName;
  const exchangeNames = getters.exchangeNames;
  const derivativesAccountsMap = getters.derivativesAccountsMap;

  function checkAccount(item, currency, type) {
    const key = `${item.id}:${currency}:${type}`;
    return derivativesAccountsMap[key];
  }

  const result = state.portfolio.activePortfolioApiKeys.reduce((memo, item) => {
    const existing = checkAccount(item, "perpetual")
    const condition =
      item.exchangeId === exchangeNames.BITFINEX &&
      item?.restrictions?.perpetuals_enabled &&
      !existing;

    if (condition) {
      const exchangeFullName = getExchangeFullName(item.exchangeId);
      const { name: apiKeyName } = getApiKey(item.id) || {};

      memo.push({
        exchangeId: item.exchangeId,
        apiKeyId: item.id,
        accountType: "perpetual",
        apiKeyName,
        currency,
        total: 0,
        exchangeFullName,
      });
    }

    return memo;
  }, []);

  return result;
}

function getGateUSDTEmptyApiKeys() {
  const store = require("@/store/store");
  const state = store.default.state;
  const getters = store.default.getters;
  const getApiKey = getters["portfolio/getApiKey"];
  const { activePortfolioApiKeys } = state.portfolio;
  const getExchangeFullName = getters.getExchangeFullName;
  const exchangeNames = getters.exchangeNames;
  const gateUnifiedAccountsSet = new Set(state.apiKeys.gateUnifiedAccounts);

  const derivativesAccountsMap = getters.derivativesAccountsMap;

  function checkAccount(item, type) {
    const key = `${item.id}:USDT:${type}`;
    return derivativesAccountsMap[key];
  }

  function getEmptyAccount(item, accountType) {
    const exchangeFullName = getExchangeFullName(item.exchangeId);
    const { name: apiKeyName } = getApiKey(item.id) || {};

    return {
      exchangeId: item.exchangeId,
      apiKeyId: item.id,
      apiKeyName,
      currency: 'USDT',
      accountType,
      total: 0,
      exchangeFullName,
    };
  }

  const result = activePortfolioApiKeys
    .filter(({ exchangeId }) => exchangeId === exchangeNames.GATE)
    .reduce((memo, item) => {
      const { id: apiKeyId } = item;

      const unifiedExisting = checkAccount(item, "unified");
      const futureUSDTMExisting = checkAccount(item, "future-usdt-m");
      const perpetualUSDTMExisting = checkAccount(item, "perpetual-usdt-m");
      const optionExisting = checkAccount(item, "option");

      if (gateUnifiedAccountsSet.has(apiKeyId)) {
        if (!unifiedExisting) {
          memo.push(getEmptyAccount(item, 'unified'));
        }
        if (!futureUSDTMExisting && item?.restrictions?.futures_enabled) {
          memo.push(getEmptyAccount(item, 'future-usdt-m'));
        }

        return memo;
      }

      // non-unified
      if (!futureUSDTMExisting && item?.restrictions?.futures_enabled) {
        memo.push(getEmptyAccount(item, 'future-usdt-m'));
      }
      if (!perpetualUSDTMExisting && item?.restrictions?.perpetuals_enabled) {
        memo.push(getEmptyAccount(item, 'perpetual-usdt-m'));
      }
      if (!optionExisting && item?.restrictions?.options_enabled) {
        memo.push(getEmptyAccount(item, 'option'));
      }

      return memo;
    }, []);

  return result;
}

function getGateBTCEmptyApiKeys() {
  const store = require("@/store/store");
  const state = store.default.state;
  const getters = store.default.getters;
  const getApiKey = getters["portfolio/getApiKey"];
  const getExchangeFullName = getters.getExchangeFullName;
  const exchangeNames = getters.exchangeNames;
  const derivativesAccountsMap = getters.derivativesAccountsMap;
  const gateUnifiedAccountsSet = new Set(state.apiKeys.gateUnifiedAccounts);

  function checkAccount(item, type) {
    const key = `${item.id}:BTC:${type}`;
    return derivativesAccountsMap[key];
  }

  function getEmptyAccount(item, accountType) {
    const exchangeFullName = getExchangeFullName(item.exchangeId);
    const { name: apiKeyName } = getApiKey(item.id) || {};

    return {
      exchangeId: item.exchangeId,
      apiKeyId: item.id,
      apiKeyName,
      currency: 'BTC',
      accountType,
      total: 0,
      exchangeFullName,
    };
  }

  
  const result = state.portfolio.activePortfolioApiKeys
  .filter(({ exchangeId }) => exchangeId === exchangeNames.GATE)
  .reduce((memo, item) => {
    const unifiedExisting = checkAccount(item, "unified");
    const perpetualCoinMExisting = checkAccount(item, "perpetual-coin-m");

    if (gateUnifiedAccountsSet.has(item.apiKeyId) && !unifiedExisting) {
      memo.push(getEmptyAccount(item, 'unified'));
    }
    if (!perpetualCoinMExisting && item?.restrictions?.perpetuals_enabled) {
      memo.push(getEmptyAccount(item, 'perpetual-coin-m'));
    }

    return memo;
  }, []);

  return result;
}

function getEmptyCollateral(apiKeyId, currency, exchangeId) {
  const store = require("@/store/store");
  const derivativesAccountsByApiKeyIdMap = store.default.getters;

  const accounts = derivativesAccountsByApiKeyIdMap[apiKeyId] ?? [];
  const contractsSet = new Set(accounts.map(({ accountType }) => accountType));
  const instruments = getInstrumentsByCurrency(currency);
  const emptyInstruments = instruments.filter(({ contractType, contract }) => !contractsSet.has(`${contractType}-${contract}`));

  return emptyInstruments.map((instrument) => {
    return {
      exchangeId,
      apiKeyId,
      accountType: `${instrument.contractType}-${instrument.contract}`,
      currency: instrument.quoteCurrency,
      balance: 0,
    };
  });
}

function getBitmexApiKeys(currency, collateral) {
  const store = require("@/store/store");
  const state = store.default.state;
  const getters = store.default.getters;
  const getApiKey = getters["portfolio/getApiKey"];
  const getExchangeFullName = getters.getExchangeFullName;
  const exchangeNames = getters.exchangeNames;

  const collateralMap = collateral.reduce((memo, item) => {
    const key = `${item.apiKeyId}:${exchangeNames.BITMEX}:${item.currency}`;
    memo[key] = item;
    return memo;
  }, {});

  function checkAccount(item) {
    const key = `${item.id}:${exchangeNames.BITMEX}:${item.currency}`;
    return collateralMap[key];
  }
  
  const currenciesSet = new Set(["USDT", "BTC", "APE", "MATIC"]);

  return state.portfolio.activePortfolioApiKeys.reduce((memo, item) => {
    const isExisting = checkAccount(item);

    const condition =
      item.exchangeId === "bitmex" &&
      currenciesSet.has(currency) &&
      !isExisting;

    if (condition) {
      const exchangeFullName = getExchangeFullName(item.exchangeId);
      const { name: apiKeyName } = getApiKey(item.id) ?? {};

      memo.push({
        exchangeId: item.exchangeId,
        apiKeyId: item.id,
        accountType: "margin",
        apiKeyName,
        currency,
        total: 0,
        exchangeFullName,
      });
    }

    return memo;
  }, []);
}

function getMarginEmptyAccounts(spotOptions, currency, exchange) {
  const store = require("@/store/store");

  const { derivativesCollateral } = store.default.state;

  let result = [];

  const accounts = spotOptions.concat(derivativesCollateral).filter((item) => item.exchangeId === exchange);

  const mappedAccounts = [
    ...new Map(accounts.map((item) => [item.apiKeyId, item])).values(),
  ];

  for (const account of mappedAccounts) {
    if (account.accountType === "spot") {
      result = result.concat(
        getEmptySpot(account.apiKeyId, currency, exchange)
      );
    } else {
      result = result.concat(
        getEmptyCollateral(account.apiKeyId, currency, exchange)
      );
    }
  }

  const apiKeys = store.default.state.portfolio.activePortfolio?.ApiKeys;

  const keyToApiKeyMap = apiKeys?.reduce((memo, item) => {
    const { exchangeId, id } = item;

    const key = `${exchangeId}:${id}`;

    memo[key] = item;

    return memo;
  }, {});

  return result.filter((item) => {
    const { exchangeId, id } = item;

    const key = `${exchangeId}:${id}`;
    const restrictions = keyToApiKeyMap[key]?.restrictions;

    if (item.accountType.includes("margin-isolated")) {
      return restrictions?.margin_enabled;
    }

    if (item.accountType.includes("perpetual")) {
      return restrictions?.perpetuals_enabled;
    }

    if (item.accountType.includes("cash_settled")) {
      return restrictions?.futures_enabled;
    }

    return false;
  });
}

export function showRawMarginAccountType(option) {
  const store = require("@/store/store");
  const { exchangeNames } = store.default.getters;

  return (
    option.accountType.includes("margin-isolated") &&
    (option.exchangeId === exchangeNames.HITBTC ||
      option.exchangeId === exchangeNames.BEQUANT)
  );
}

export function showRawPerpetualAccountType(option) {
  const store = require("@/store/store");
  const { exchangeNames } = store.default.getters;

  return (
    option.accountType.includes("perpetual-") &&
    (option.exchangeId === exchangeNames.HITBTC ||
      option.exchangeId === exchangeNames.BEQUANT ||
      option.exchangeId === exchangeNames.BITFINEX)
  );
}

const searchBy1 = ["exchangeFullName", "apiKeyName", "exchangeId"];
const searchBy2 =  [
  "formattedAccountType",
  "exchangeId",
  "exchangeFullName",
];
const searchBy3 = ["accountType", "exchangeId", "exchangeFullName"];

export function getGroupedOptions(options, currency) {
  const store = require("@/store/store");
  const { exchangeNames } = store.default.getters;

  const grouped = {};

  for (const option of options) {
    const uid = `${option.apiKeyId}-${option.accountType}`;
    const item = {
      ...option,
      uid,
      searchBy: searchBy1
    };

    if (!grouped[item.apiKeyId]) {
      grouped[item.apiKeyId] = {
        ...item,
        options: [],
        total: "0",
      };
    }

    grouped[item.apiKeyId].options.push(item);
  }

  const result = Object.values(grouped).map((item) => {
    item.total = item.options
      .reduce((memo, current) => add(memo, Math.max(current.total, 0)), "0");
    item.total = getRoundedDownValue(item.total, item.currency);

    if (item.options.length > 1) {
      item.options = item.options
        .map((option) => processNestedOption(option, exchangeNames))
        .filter((filteredOption) =>
          !(filteredOption.exchangeId === exchangeNames.HUOBI &&
            (filteredOption.formattedAccountType === "perpetuals" ||
              filteredOption.accountType === "future") &&
            currency === CURRENCIES.USDT)
        );
    } else {
      delete item.options;
    }

    return item;
  });

  return result;
}

// Helper to process nested options
function processNestedOption(option, exchangeNames) {
  option.nested = true;
  option.groupBy = true;
  option.searchBy = searchBy2;
  option.groupKey = "formattedAccountType";
  option.formattedAccountType = option.accountType;

  if (showRawPerpetualAccountType(option)) {
    option.formattedAccountType = "Perpetual";
    option.searchBy = searchBy3;
  }

  if (option.exchangeId === exchangeNames.HUOBI) {
    const isUSDTM = option.accountType.toLowerCase() === "perpetuals-usdt-m";

    if (!isUSDTM) {
      option.formattedAccountType = option.accountType
        .replace(/-\w+$/i, "")
        .toLowerCase();
      option.searchBy = searchBy3;
    }
  }

  if (option.exchangeId === exchangeNames.OKEX) {
    option.formattedAccountType = option.accountType.match(/^\w+/i)[0];
    option.searchBy = searchBy3
  }

  if (showRawMarginAccountType(option)) {
    option.formattedAccountType = "Margin-isolated";
    option.searchBy = searchBy3;
  }

  option.uid = `${option.apiKeyId}-${option.accountType}-nested`;
  option.total = getRoundedDownValue(option.total, option.currency);

  return option;
}

export default function (
  currency,
  from = null,
  filterAccountsOptions = () => {}
) {
  if (!currency) {
    return [];
  }

  const store = require("@/store/store");
  const getters = store.default.getters;

  const state = store.default.state;
  const { derivativesCollateral } = state;
  const { balance, exchangeNames, getExchangeFullName, getAliasCurrency } = getters;
  const gateUnifiedAccountsSet = new Set(state.apiKeys.gateUnifiedAccounts);

  let spotOptions = balance.reduce((memo, item) => {
    if (gateUnifiedAccountsSet.has(item.apiKeyId)) {
      return memo;
    }

    let total = item.balance[currency];

    if (USDT_CURRENCIES_SET.has(currency)) {
      total = item.balance.USDT ?? total;
    }

    memo.push({
      ...item,
      currency,
      accountType: "spot",
      total: total ?? 0,
    });

    return memo;
  }, []);

  const hitbtcMarginEmptyAccounts = getMarginEmptyAccounts(
    spotOptions,
    currency,
    exchangeNames.HITBTC
  );

  const bequantMarginEmptyAccounts = getMarginEmptyAccounts(
    spotOptions,
    currency,
    exchangeNames.BEQUANT
  );

  const getApiKey = getters["portfolio/getApiKey"];

  const collateral = derivativesCollateral
    .filter(({ exchangeId, apiKeyId, accountType }) => {
      if ([exchangeNames.FTX, exchangeNames.OKEX_V5].includes(exchangeId)) {
        return false;
      }

      if (gateUnifiedAccountsSet.has(apiKeyId) && ['option', 'perpetual-usdt-m'].includes(accountType)) {
        return false;
      }

      return true;
    })
    .concat(hitbtcMarginEmptyAccounts)
    .concat(bequantMarginEmptyAccounts)
    .map((item, index) => {
      const { exchangeId, currency, accountType, apiKeyId } = item;
      const exchangeFullName = getExchangeFullName(exchangeId);
      const total = parseFloat(item.balance);
      const { name: apiKeyName } = getApiKey(item.apiKeyId) ?? {};
      const formattedCurrency = getAliasCurrency(exchangeId, currency);

      return {
        exchangeId,
        apiKeyId,
        accountType,
        apiKeyName,
        currency: formattedCurrency,
        total,
        exchangeFullName,
        label: index,
      };
    });

  spotOptions = spotOptions.concat(collateral);

  const bitmexData = collateral
    .filter((item) => item.exchangeId === exchangeNames.BITMEX)
    .map(mapXBTToBTC);

  let isBitmexExist = bitmexData.length >= 1;

  if (from) {
    isBitmexExist =
      bitmexData.filter((item) => filterAccountsOptions(item, "from")).length >=
        1 || from?.exchangeId === exchangeNames.BITMEX;
  }

  spotOptions = spotOptions
    .map(mapXBTToBTC)
    .concat(!isBitmexExist ? [] : getBitmexApiKeys(currency, collateral))
    .concat(getBitfinexMarginApiKeys(currency))
    .concat(getBitfinexPerpetualApiKeys(currency))
    .concat(getGateUSDTEmptyApiKeys())
    .concat(getGateBTCEmptyApiKeys())
    .filter((item) => {
      let formattedCurrency = currency;

      if (item.accountType !== "spot") {
        formattedCurrency = USDT_CURRENCIES_SET.has(formattedCurrency)
          ? "USDT"
          : formattedCurrency;
      }

      return (
        item.currency === formattedCurrency &&
        item.exchangeId !== exchangeNames.FTX
      );
    });

  if (from) {
    spotOptions = spotOptions.filter((item) =>
      filterAccountsOptions(item, "from")
    );
  }

  spotOptions = spotOptions
    .map((item, index) => {
      return {
        ...item,
        currency:
        USDT_CURRENCIES_SET.has(item.currency) &&
          ![exchangeNames.BINANCE_CUSTODY, exchangeNames.FIREBLOCKS].includes(
            item.exchangeId
          )
            ? "USDT"
            : item.currency,
        label: index,
      };
    })
    .sort(
      (
        { apiKeyName: apiKeyName1, exchangeId: exchangeId1 },
        { apiKeyName: apiKeyName2, exchangeId: exchangeId2 }
      ) => {
        const key1 = `${exchangeId1}:${apiKeyName1}`;
        const key2 = `${exchangeId2}:${apiKeyName2}`;

        return key1.localeCompare(key2);
      }
    );

  return getGroupedOptions(spotOptions);
}
